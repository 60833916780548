import { useQuery } from '@tanstack/react-query'
import { useSupabase } from 'hooks/useSupabase'

import { StripePrice } from 'types'

export const usePrices = () => {
  const supabase = useSupabase()

  const prices = useQuery({
    queryKey: ['prices'],
    queryFn: () =>
      supabase.functions.invoke('list-stripe-prices', {
        body: { product_id: import.meta.env.VITE_STRIPE_PRODUCT_ID },
      }),
  })

  const activePrices = prices.data?.data?.filter((price: StripePrice) => price.active)

  const weeklyPrice = activePrices?.find(
    (price: StripePrice) => price.recurring.interval === 'week',
  )

  const monthlyPrice = activePrices?.find(
    (price: StripePrice) => price.recurring.interval === 'month',
  )

  const yearlyPrice = activePrices?.find(
    (price: StripePrice) => price.recurring.interval === 'year',
  )


  return {
    isLoading: prices.isLoading,
    monthlyPrice,
    yearlyPrice,
    weeklyPrice,
  }
}
