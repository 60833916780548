import { CTAButton } from 'components/CTAButton'
import { formatPrice } from 'helpers'
import getCurrencyAndPriceInCents from 'helpers/getCurrencyAndPriceInCents'
import getIntroPriceInCents from 'helpers/getIntroPriceInCents'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { StripeCoupon, StripePrice } from 'types'

type YearlyPlanProps = {
  introPriceCoupon: StripeCoupon | undefined
  isLoading: boolean
  locale: string
  userCurrency: string
  yearlyPrice: StripePrice
  timeLeft: string
}

export default function YearlyPlan({
  introPriceCoupon,
  isLoading,
  locale,
  userCurrency,
  yearlyPrice,
  timeLeft,
}: YearlyPlanProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { currency: yearlyPriceCurrency, priceInCents: yearlyPriceInCents } =
    getCurrencyAndPriceInCents({
      stripePrice: yearlyPrice,
      userCurrency,
    })

  const yearlyIntroPriceInCents = getIntroPriceInCents({
    introPriceCoupon,
    priceInCents: yearlyPriceInCents,
  })

  return (
    <div>
      <div className="-mb-5 flex h-24 items-start justify-center rounded-t-3xl border-x-2 border-t-2 border-[#9699AE40]">
        <p className="mt-5 text-2xl font-bold uppercase">{t('plans.save')}</p>
      </div>

      <div className="rounded-3xl border-2 border-[#222222] bg-white p-8 text-center">
        <p className="text-4xl font-bold">12</p>

        <p className="text-lg uppercase">{t('plans.months')}</p>

        {isLoading ? (
          <div className="mb-7 mt-6 flex flex-col items-center justify-center">
            <div className="h-6 w-3/5 animate-pulse rounded-lg bg-[#BBBFCB]" />

            <div className="mt-6 h-5 w-4/5 animate-pulse rounded-lg bg-[#BBBFCB]" />
          </div>
        ) : (
          <>
            <div className="mt-8 text-2xl">
              {yearlyIntroPriceInCents && (
                <s>
                  {formatPrice({
                    currency: yearlyPriceCurrency,
                    locale,
                    priceInCents: Math.floor(yearlyPriceInCents / 12),
                  })}{' '}
                  / {t('plans.month')}
                </s>
              )}

              <p className="font-bold">
                {formatPrice({
                  currency: yearlyPriceCurrency,
                  locale,
                  priceInCents: Math.floor((yearlyIntroPriceInCents || yearlyPriceInCents) / 12),
                })}{' '}
                / {t('plans.month')}
              </p>
            </div>

           

            <div className="mt-8 text-[#BBBFCB]">
              {timeLeft !== '00:00' && (
                <p className="mb-6 font-semibold">
                  <span className="bg-[#D65353] text-white rounded-full px-4 py-2">
                    {t('plans.offerEnds', { countdown: timeLeft })}
                  </span>
                </p>
              )}

              {yearlyIntroPriceInCents && (
                <s>
                  {t('plans.billed', {
                    price: formatPrice({
                      currency: yearlyPriceCurrency,
                      locale,
                      priceInCents: yearlyPriceInCents,
                    }),
                  })}
                </s>
              )}

              <p className="mb-6 font-semibold">
                {t('plans.billed', {
                  price: formatPrice({
                    currency: yearlyPriceCurrency,
                    locale,
                    priceInCents: yearlyIntroPriceInCents || yearlyPriceInCents,
                  }),
                })}
              </p>
            </div>
          </>
        )}

        <CTAButton
          text={t('plans.cta')}
          onClick={() =>
            navigate(RoutePaths.Checkout, {
              state: {
                couponId: introPriceCoupon?.id,
                currency: yearlyPriceCurrency,
                duration: 12,
                priceId: yearlyPrice.id,
              },
            })
          }
        />

        <p className="mt-4">{t('plans.cancel')}</p>

        <p className="mt-4 font-bold">{t('plans.guarantee')}</p>
      </div>
    </div>
  )
}
