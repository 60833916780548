import { CTAButton } from 'components/CTAButton'
import { formatPrice } from 'helpers'
import getCurrencyAndPriceInCents from 'helpers/getCurrencyAndPriceInCents'
import getIntroPriceInCents from 'helpers/getIntroPriceInCents'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { StripeCoupon, StripePrice } from 'types'

type WeeklyPlanProps = {
  introPriceCoupon: StripeCoupon | undefined
  isLoading: boolean
  locale: string
  weeklyPrice: StripePrice
  userCurrency: string
  timeLeft: string
}

export default function MonthlyPlan({
  introPriceCoupon,
  isLoading,
  locale,
  weeklyPrice,
  userCurrency,
  timeLeft,
}: WeeklyPlanProps) {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { currency: weeklyPriceCurrency, priceInCents: weeklyPriceInCents } =
    getCurrencyAndPriceInCents({
      stripePrice: weeklyPrice,
      userCurrency,
    })

  const weeklyIntroPriceInCents = getIntroPriceInCents({
    introPriceCoupon,
    priceInCents: weeklyPriceInCents,
  })

  return (
    <div className="rounded-3xl border-2 border-[#9699AE40] bg-white p-8 text-center">
      <p className="text-4xl font-bold">1</p>

      <p className="text-lg uppercase">{t('plans.week')}</p>

      {isLoading ? (
        <div className="mb-7 mt-7 flex flex-col items-center justify-center">
          <div className="h-6 w-3/5 animate-pulse rounded-lg bg-[#BBBFCB]" />
        </div>
      ) : (
        <div className="mt-6 text-2xl">
          {weeklyIntroPriceInCents && (
            <s className="mb-6">
              {formatPrice({
                currency: weeklyPriceCurrency,
                locale,
                priceInCents: weeklyPriceInCents,
              })}{' '}
              / {t('plans.week')}
            </s>
          )}

          <p className="mb-6 font-semibold">
            {formatPrice({
              currency: weeklyPriceCurrency,
              locale,
              priceInCents: weeklyIntroPriceInCents || weeklyPriceInCents,
            })}{' '}
            / {t('plans.week')}
          </p>

          {timeLeft !== '00:00' && (
            <p className="mb-6 text-base font-semibold">
              <span className="bg-[#D65353] text-white rounded-full px-4 py-2">
                {t('plans.offerEnds', { countdown: timeLeft })}
              </span>
            </p>
          )}
        </div>
      )}

      <CTAButton
        text={t('plans.cta')}
        onClick={() =>
          navigate(RoutePaths.Checkout, {
            state: {
              couponId: introPriceCoupon?.id,
              currency: weeklyPriceCurrency,
              duration: 1,
              priceId: weeklyPrice.id,
            },
          })
        }
      />

      <p className="mt-4">{t('plans.cancel')}</p>

      <p className="mt-4 font-bold">{t('plans.guarantee')}</p>
    </div>
  )
}
