import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import clsx from 'classnames'
import { format } from 'date-fns'
import { formatPrice } from 'helpers'
import { trackInitiateCheckoutEvent } from 'helpers/facebookTracking'
import { useUserLocale } from 'hooks/useUserLocale'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { RoutePaths } from 'routes'

interface Props {
  amount: number
  amountOriginal: number
  currency: string
  duration: number
  priceId: string
  subscriptionId: string
}

export const CheckoutForm: React.FC<Props> = ({
  amount,
  amountOriginal,
  currency,
  duration,
  priceId,
  subscriptionId,
}) => {
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const elements = useElements()
  const locale = useUserLocale()
  const stripe = useStripe()
  const { t } = useTranslation()

  const currentDate = new Date()
  const futureDate = new Date(currentDate)

  if (duration === 1) {
    futureDate.setDate(currentDate.getDate() + 7)
  } else {
    futureDate.setMonth(currentDate.getMonth() + duration)
  }

  const formattedFutureDate = format(futureDate, 'dd.MM.yyyy')

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setSubmitButtonDisabled(true)
    setIsSubmitting(true)

    if (!stripe || !elements) {
      return
    }

    const returnUrl = `${window.location.origin}${RoutePaths.PaymentConfirmation}?price_id=${priceId}&subscription_id=${subscriptionId}`

    try {
      const { error } = await stripe.confirmPayment({
        confirmParams: {
          return_url: returnUrl,
        },
        elements,
      })

      if (error) {
        toast(error.message, {
          type: 'error',
          position: 'bottom-center',
        })
      }
    } catch (error) {
      toast(t('checkout.paymentStatus.failure'), {
        type: 'error',
      })
    }

    setSubmitButtonDisabled(false)
    setIsSubmitting(false)
  }

  const paymentElementOptions = {
    paymentMethodOrder: ['card', 'paypal', 'apple_pay', 'google_pay'],
  }

  const isIntroPrice = amount !== amountOriginal

  const isYearlyPrice = duration === 12

  useEffect(() => {
    setSubmitButtonDisabled(!stripe)

    const trackEvent = async () => {
      await trackInitiateCheckoutEvent({
        amountInCents: amount,
        currency: currency,
      });
    };

    trackEvent().catch((error) => console.error(error));
  }, [amount, currency, stripe])

  return (
    <div>
      <form className="space-y-8 md:space-y-16" onSubmit={handleSubmit}>
        <div className="space-y-4">
          <div className="rounded-2xl border border-neutral-400 bg-white p-5">
            <PaymentElement options={paymentElementOptions} />
          </div>

          <div className="rounded-2xl border border-neutral-400 bg-white p-5">
            <h3 className="font-semibold">{t('checkout.orderSummary.fullaccessHeadline')}</h3>
            <p className="mb-8 mt-2 leading-4">{t('checkout.orderSummary.fullaccessContent')}</p>

            <h3 className="font-semibold">{t('checkout.orderSummary.durationHeadline')}</h3>
            <p className="mb-8 mt-2 leading-4">
              {duration}{' '}
              {isYearlyPrice ? t('checkout.orderSummary.months') : t('checkout.orderSummary.week')}
            </p>

            <h3 className="font-semibold">{t('checkout.orderSummary.renewalHeadline')}</h3>

            <p className="mt-2 leading-4">{formattedFutureDate}</p>

            <p className="mb-8 mt-2 leading-4">{t('checkout.orderSummary.renewalDisclaimer')}</p>

            {isIntroPrice && (
              <p className="line-through">
                {t(isYearlyPrice ? 'checkout.thisYearPrice' : 'checkout.thisWeekPrice', {
                  price: formatPrice({ currency, locale, priceInCents: amountOriginal }),
                })}
              </p>
            )}

            <p className="mt-4 font-bold">
              {isIntroPrice
                ? t(isYearlyPrice ? 'checkout.firstYearPrice' : 'checkout.firstWeekPrice', {
                  price: formatPrice({
                    currency,
                    locale,
                    priceInCents: amount,
                  }),
                })
                : t(isYearlyPrice ? 'checkout.thisYearPrice' : 'checkout.thisWeekPrice', {
                  price: formatPrice({
                    currency,
                    locale,
                    priceInCents: amount,
                  }),
                })}
            </p>
          </div>
        </div>

        <div className="sticky bottom-0 p-4">
          <button
            disabled={submitButtonDisabled}
            className={clsx(
              'w-full rounded-full bg-[#222222] px-7 py-6 text-lg font-bold uppercase text-white shadow-[0_25px_30px_-10px_rgba(38,57,77,0.6)] transition-opacity focus-visible:outline-none',
              submitButtonDisabled ? 'opacity-60' : 'opacity-100',
            )}
            type="submit"
          >
            {isSubmitting ? t('checkout.submittingPayment') : t('checkout.submitPayment')}
          </button>
        </div>
      </form>

      <ToastContainer />
    </div>
  )
}
