export enum RoutePaths {
  Root = '/',
  Password = '/password',
  Email = '/email',
  BuildingPlan = '/building-plan',
  Plans = '/plans',
  Checkout = '/checkout',
  PaymentConfirmation = '/payment-confirmation',
  ThankYou = '/thank-you',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  Login = '/login',
  HelpCenter = '/help',
  Cancelation = '/help/cancelation',
  CancelationApple = '/help/cancelation/apple',
  CancelationGoogle = '/help/cancelation/google',
  CancelationCompleted = '/help/cancelation/completed',
  Refund = '/help/refund',
  RefundApple = '/help/refund/apple',
  RefundGoogle = '/help/refund/google',
  RefundCompleted = '/help/refund/completed',

  // Onboarding
  Drivers = '/drivers',
  Gender = '/onboarding/gender',
  Glad = '/onboarding/glad',
  Sleep = '/onboarding/sleep',
  GetUp = '/onboarding/get-up',
  Energy = '/onboarding/energy',
  Lifestyle = '/onboarding/lifestyle',
  GoalsOnb1 = '/onboarding/goals',
  Motivation = '/onboarding/motivation',
  DistractionsOnb1 = '/onboarding/distractions',
  Procrastination = '/onboarding/procrastination',
  DriversOnb1 = '/onboarding/drivers',
  Building = '/onboarding/building',
  DesireOnb1 = '/onboarding/desire',
  StatementAnxiousOnb1 = '/onboarding/statement-anxious',
  StatementNotEnoughTimeOnb1 = '/onboarding/statement-time',
  StatementConcentrationOnb1 = '/onboarding/statement-concentration',
  StatementRegretnOnb1 = '/onboarding/statement-regret',
  ReadyOnb1 = '/onboarding/ready',
  FeedbackOnb1 = '/onboarding/feedback',
}
