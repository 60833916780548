import { supabaseClient } from 'api/supabase'
import { ContinueButton } from 'components/ContinueButton'
import { Input } from 'components/Input'
import { TextModal } from 'components/TextModal'
import { trackCompleteRegistrationEvent } from 'helpers/facebookTracking'
import { isEmailValid } from 'helpers/validators'
import Cookies from 'js-cookie'
import { GetFlagValueLoaderData } from 'loaders/getFlagValueLoader'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useLocalStorage } from 'react-use'
import { RoutePaths } from 'routes'

export const Email = () => {
  const { flagValue } = useLoaderData() as GetFlagValueLoaderData
  const [email, setEmail] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [persistedPassword] = useLocalStorage('password') as string[]

  const navigate = useNavigate()
  const supabase = supabaseClient
  const { t } = useTranslation()

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const createUserAndNavigate = async () => {
    console.log("flagValue", flagValue)
    if (isEmailValid(email)) {
      const confidenceVisitorId = Cookies.get("cnfdVisitorId");

      const options = {
        data: {
          confidence_visitor_id: confidenceVisitorId ?? "unknown",
          sign_up_platform: "web",
        },
      };

      const { error } = await supabase.auth.signUp({
        email: email,
        password: persistedPassword,
        options: options
      })

      if (error?.status === 400) {
        setModalOpen(true)
      } else if (error) {
        toast(error.message, {
          type: 'error',
          position: 'bottom-center',
        })
      } else {
        await trackCompleteRegistrationEvent()
        navigate(RoutePaths.BuildingPlan)
      }
    }
  }

  const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!isEmailValid(email)) {
      return
    }

    createUserAndNavigate()
  }

  return (
    <>
      <div className="flex w-full flex-col space-y-8">
        <div className="basis-2/5 space-y-4">
          <h1 className="mb-4 text-2xl font-bold">{t('email.headline')}</h1>

          <h2 className="text-lg font-semibold text-[#BBBFCB]">{t('email.subheadline')}</h2>
        </div>

        <form className="flex basis-3/5 flex-col justify-between space-y-6" onSubmit={onFormSubmit}>
          <div className="space-y-4">
            <Input
              type="email"
              placeholder={t('email.placeholder')}
              value={email}
              onChange={onEmailChange}
            />

            <p className="px-2 font-semibold text-[#BBBFCB]">{t('email.disclaimer')}</p>
          </div>

          <ContinueButton disabled={!isEmailValid(email)} />
        </form>
      </div>

      <TextModal
        open={modalOpen}
        setOpen={setModalOpen}
        text={t('email.modalText')}
        buttonText={t('email.modalButton')}
        onButtonClick={() => navigate(RoutePaths.Login)}
      />

      <ToastContainer />
    </>
  )
}

